import {Injectable} from '@angular/core';
import {Constant} from "../../../common/constant";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { createRequestOption } from 'src/app/common/utils/request-util';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvestorService {

  constructor(
    private http: HttpClient,
    private fileSaverService: FileSaverService
  ){}

  getIssuers(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_issuer, body);
  }
  orderPending(requestBody) {
    return this.http.post(environment.urlBackEndV2 + Constant.api_investors_orders_pending , requestBody);
  }
  getDetailOrder(orderCode) {
    return this.http.get(environment.urlBackEnd + Constant.api_investors_orders_pending_detail + orderCode);
  }
  getBanks() {
    return this.http.get(environment.urlBackEnd +  Constant.api_investor_get_banks);
  }
  getBankAccounts() {
    return this.http.get(environment.urlBackEnd + Constant.api_investor_get_banks_account);
  }
  getProducts(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_products_filter, body);
  }
  getProductById(id) {
    return this.http.get(environment.urlBackEnd + Constant.api_common_products_id + id);
  }
  getReport(id, body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_user_report_document_1 + id + Constant.api_user_report_document_2, body);
  }
  getReportProduct(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_get_report_products_filter, body);
  }
  getInfoIssuer(id) {
    return  this.http.get(environment.urlBackEnd + Constant.api_common_issuer + '/' + id);
  }
  getBondIssuer(id) {
    return  this.http.get(environment.urlBackEnd + Constant.api_common_bond_issuer + '/' + id);
  }
  following(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_following, body);
  }
  unfollow(id) {
    let url = environment.urlBackEnd +  Constant.api_common_unfollowing;
    url = url.replace('{issuerId}', id);
    return  this.http.post(url, {});
  }
  updateAvatar(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_user_update_avatar, body);
  }
  updateProfile(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_user_update_profile, body);
  }
  updateProfileStep1(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/step-1', body);
  }
  updateProfileStep2(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/step-2', body);
  }
  updateProfileStep2Version2(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/v2/step-2', body);
  }
  createEsignProfileStep2(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_investors_step_2_esign_create, body);
  }
  confirmEsignProfileStep2(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_investors_step_2_esign_confirm, body);
  }
  updateProfileStep3(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/step-3/create', body);
  }
  confirmUpdateProfileStep3(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_security_otp_update_step_3, body);
  }
  updateProfileStep3Version2(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/v2/step-3/create', body);
  }
  confirmUpdateProfileStep3Version2(body) {
    return  this.http.post(environment.urlBackEnd + '/users/profile/v2/step-3/confirm', body);
  }
  updateProfileStep4(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_investors_update_fatca,  body);
  }
  createEsignProfileStep3(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_investors_step_3_esign_create, body);
  }
  confirmEsignProfileStep3(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_investors_step_3_esign_confirm, body);
  }
  followingBond(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_bond_following, body);
  }
  unfollowBond(id) {
    let url = environment.urlBackEnd + Constant.api_common_bond_unfollowing;
    url = url.replace('{issuerId}', id);
    return  this.http.post(url, {});
  }
  removeBank(id) {
    return this.http.delete(environment.urlBackEnd + Constant.api_user_delete_bank + id);
  }
  createBank(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_user_add_bank_account, body);
  }
  confirmCreateBank(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_user_add_bank_account_confirm, body);
  }
  updateBank(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_user_edit_bank_account, body);
  }
  confirmUpdateBank(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_user_edit_bank_account_confirm, body);
  }
  confirmUpdatePassport(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_change_passport, body);
  }
  addDocumentFileInvestor(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_document_file_investor, body);
  }
  deleteDocumentFileInvestor(fileId) {
    return  this.http.delete(environment.urlBackEnd + Constant.api_common_document_file_investor + fileId);
  }
  updateSecondInfo(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_user_second_profile, body);
  }
  changePass(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_user_change_pass, body);
  }
  changeMailOTP(requestBody) {
    return  this.http.post(environment.urlBackEnd + Constant.api_security_otp_change_mail, requestBody);
  }
  confirmUpdateMail(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_change_mail, body);
  }
  changePhoneOTP(body) {
    return  this.http.post(environment.urlBackEnd +  Constant.api_security_otp_change_phone, body);
  }
  confirmUpdatePhone1(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_phone_1, body);
  }
  confirmUpdatePhone2(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_phone_2, body);
  }
  sellProduct(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_sell, body);
  }
  changeMinAmountSIP(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_update_amount_SIP, body);
  }
  addCart(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_add_cart, body);
  }
  registerSip(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_register_sip, body);
  }
  checkReadProspectusSip(req) {
    const options = createRequestOption(req);
    return this.http.get(environment.urlBackEnd + Constant.api_investors_check_confirm_prospectus, {params: options});
  }
  confirmRegisterSip(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_register_sip, body);
  }
  updateCart(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_update_cart, body);
  }
  removeCart(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_remove_cart, body);
  }
  cancelOrder(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_cancel_order, body);
  }
  ignorePayment(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_ignore_payment, body);
  }
  confirmReadDocument(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_confirm_prospectus, body);
  }
  confirmPayment(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_investors_approve_payment_one_step, body);
  }
  confirmOTPApprovePayment(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_investors_approve_payment, body);
  }
  getInvestorTransactionHistory(body) {
    return this.http.post(environment.urlBackEndV2 + Constant.api_investor_transaction_history, body);
  }
  targetFund(productId, schemeID, programId) {
    let url = environment.urlBackEnd + Constant.api_investor_get_target_fund;
    url = url.replace('{productId}', productId);
    url = url.replace('{programId}', programId);
    url = url.replace('{schemeID}', schemeID);
    return this.http.get(url)
  }
  
  getDestPrograms(body) {
    let url = environment.urlBackEnd + Constant.api_investor_get_target_program;
    url = url.replace('{productId}', body.productId);
    url = url.replace('{schemeId}', body.schemeId);
    url = url.replace('{programId}', body.programId);
    url = url.replace('{destProductId}', body.destProductId);
    return this.http.get( url)
  }
  transferProduct(request) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_transfer_product, request);
  }
  getIssuerId(id) {
    return this.http.get(environment.urlBackEnd + Constant.api_common_issuer + '/' + id);
  }
  getAgencies(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_agencies_register_trade, body);
  }
  sign(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_sign, body);
  }
  registerAgency(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_register_agency, body);
  }
  cancelProfileTrade(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_cancel_profile_trade, body);
  }
  postRequest(url, body) {
    return this.http.post(environment.urlBackEnd + url, body);
  }
  getBondRemainPeriods(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_bond_remain_period, body);
  }
  getCDRemainPeriods(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_cd_remain_period, body);
  }
  getAssets(body) {
    return this.http.post(environment.urlBackEnd +   Constant.api_investors_assets_management, body);
  }
  getTotalAssets() {
    return this.http.get(environment.urlBackEnd +   Constant.api_investors_total_assets_management);
  }
  confirmSell(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_investors_otp_sell_confirm, body);
  }
  confirmTransfer(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_security_otp_confirm_transfer_product, body);
  }
  getEstimate(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_investors_estimate_sell_fund, body);
  }
  getEstimateTransfer(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_investors_estimate_transfer_fund, body);
  }
  uploadFile(body) {
    return this.http.post(Constant.api_upload_file, body);
  }
  eSign(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_investors_esign_create, body);
  }
  confirmOTPESign(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_investors_esign_confirm, body);
  }
  exportHistoryTransaction(body) {
    return this.http.post(environment.urlBackEndV2 +  Constant.api_investor_export_transaction_history, body, {responseType:'blob',  observe: 'events'})
  }
  getListSignDocuments(body) {
    // const options = createRequestOption(req);
    return this.http.post(environment.urlBackEnd + Constant.api_investors_get_list_sign_document, body);
  }
  signDocument(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_sign_document_change_profile, body);
  }
  confirmSignDocument(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_confirm_sign_document_change_profile, body);
  }
  exportFile(url, data) {
    return this.http.post(environment.urlBackEnd + url, data, {responseType:'blob',  observe: 'events'});
  }
  getListSurveyIPO(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_get_list_survey_ipo, body);
  }
  signIPO(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_sign_ipo, body);
  }
  confirmSignIPO(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_confirm_sign_ipo, body);
  }
  getResource(): Observable<any> {
    return this.http.get(environment.urlBackEnd + Constant.api_common);
  }
  addReferralCode(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_add_referral_code, body);
  }
  checkEsignBeforeBuy(req) {
    const options = createRequestOption(req);
    return this.http.get(environment.urlBackEnd + Constant.api_investors_check_esign_before_buy + '/' + req.productId, {params: options});
  }
  updateAdditionalInfo(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investor_save_additional_info, body);
  }
  updateFatca(body) {
    return this.http.put(environment.urlBackEnd + Constant.api_investors_update_fatca, body);
  }
  confirmInvestorType(body) {
    return this.http.put(environment.urlBackEnd + Constant.api_investors_confirm_investor_type, body);
  }
  deleteAccount() {
    return this.http.post(environment.urlBackEnd + Constant.api_delete_account, {});
  }
  confirmDeleteAccount(body) {
    return this.http.post(environment.urlBackEnd +  Constant.api_confirm_delete_account, body);
  }
  getRejectOrders(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_load_list_reject_orders, body);
  }
  requestRefund(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_request_refund, body);
  }
  getContributeGoalOrders(req) {
    const options = createRequestOption(req);
    return this.http.get(environment.urlBackEnd + Constant.api_investors_load_contribute_goal_orders, {params: options});
  }
  confirmPaymentContributeGoalOrders(req) {
    const options = createRequestOption(req);
    return this.http.get(environment.urlBackEnd + Constant.api_investors_confirm_payment_contribute_goal_orders, {params: options});
  }
  createSignChangeName(body) {
    return  this.http.post(environment.urlBackEnd +  Constant.api_investors_sign_change_name, body);
  }
  confirmSignChangeName(body) {
    return  this.http.post(environment.urlBackEnd +  Constant.api_investors_sign_change_confirm, body);
  }
}
