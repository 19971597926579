<div class="row" *ngIf="topHolding?.length || bondList?.length; else noData">
  <div class="col-12 mt-3" *ngIf="topHolding?.length">
    <div class="row modal-row modal-heading">
      <div class="col-3 col-sm-3 tt-buy-hgt">
        <label class="title-fee">{{'Cổ phiếu' | translate}}</label>
        <br>
      </div>
      <div class="col-3 col-sm-3 tt-buy-hgt">
        <label class="title-fee">{{'Ngành' | translate}}</label>
        <br>
      </div>
      <div class="col-2 col-sm-2  text-right tt-buy-hgt">
        <label class="title-fee" style="white-space:nowrap">% GAV</label>
        <br>
      </div>
      <div class="col-4 col-sm-4 tt-buy-hgt">
        <label class="title-fee" style="white-space:nowrap">Giá (ngàn đồng)</label>
        <br>
      </div>
    </div>
    <div class="row row-color" *ngFor="let item of topHolding">
      <div class="col-3 col-sm-3 tt-buy-hgt no-padding-right">
        <label>{{item.stockCode}}</label>
      </div>
      <div class="col-3 col-sm-3 tt-buy-hgt">
        <label class="label-name text-left">{{item.industry}}</label>
      </div>
      <div class="col-2 col-sm-2 text-right tt-buy-hgt">
        <label class="label-name">{{item.netAssetPercent}}</label>
      </div>
      <div class="col-4 col-sm-4 text-right tt-buy-hgt">
        <div class="d-flex flex-column">
          <label class="label-name">{{item.price}}</label>
          <label class="label-name d-flex"
            [ngClass]="item.changeFromPreviousPercent > 0 ? 'up' : (item.changeFromPreviousPercent < 0  ? 'down' : '')">
            {{item.changeFromPreviousPercent > 0 ? '+' : ''}}
            {{item.changeFromPrevious}}/{{item.changeFromPreviousPercent}}%
          </label>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="bondList?.length">
    <div class="row modal-row modal-heading">
      <div class="col-3 col-sm-3 tt-buy-hgt">
        <label class="title-fee">{{'Trái phiếu' | translate}}</label>
        <br>
      </div>
      <div class="col-4 col-sm-4 tt-buy-hgt">
        <label class="title-fee">{{'Ngành' | translate}}</label>
        <br>
      </div>
      <div class="col-5 col-sm-5 text-right tt-buy-hgt">
        <label class="title-fee" style="white-space:nowrap">{{'% GAV' | translate}}</label>
        <br>
      </div>
    </div>
    <div class="row row-color" *ngFor="let item of bondList">
      <div class="col-3 col-sm-3 tt-buy-hgt no-padding-right">
        <label>{{item.stockCode}}</label>
      </div>
      <div class="col-7 col-sm-7 tt-buy-hgt">
        <label class="label-name text-left">{{item.industry}}</label>
      </div>
      <div class="col-2 col-sm-2 text-right tt-buy-hgt">
        <label class="label-name">{{item.netAssetPercent}}</label>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="updateTime" [ngClass]="isDevice && 'ml-1'">
  <span>Theo báo cáo tháng {{updateTime}}</span>
</div>
<ng-template #noData>
  <div class="block-nodata">
    <img src="./assets/images/icon/nodata.png" alt="">
    <h5>Danh mục đầu tư trống</h5>
    <span>Không có dữ liệu</span>
  </div>
</ng-template>