import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Constant } from "./common/constant";
import { TranslateService } from "@ngx-translate/core";
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { DialogService } from "./common/service/dialog.service";
import { environment } from "../environments/environment";
import { UserService } from "./common/service/user.service";
import {
  Dispatcher,
  GetInfoUser,
  StoreTheme,
  AppQueries,
  GetDataSource,
} from "./state";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable, Subscription } from "rxjs";
import { EventManagerService } from "./common/service/event-manager.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NewOTPComponent } from "src/app/common/components/otp/otp.component";
import { ProductService } from "src/app/product/product.service";
import { InvestorService } from "src/app/main/investor/services/investor.service";
import { ThemeService } from "src/app/common/service/theme.service";
import { UserProfile } from "./common/models/users.model";
import { MaintainceService } from "./main/services/maintaince.service";
import { AccountService } from "./main/services/account.service";
import { RoboService } from "./robo/robo.service";
import { DetailGold } from "./product/components/detail-gold/detail-gold";
import { DetailIPOFund } from "./product/components/detail-ipo-fund/detail-ipo-fund";
import { ConfirmPopupComponent } from "./common/components/confirm-popup/confirm-popup";
import { DateUtil } from "./common/utils/date.util";
import { Gold } from "./common/models/gold.model";
import { TradingFund } from "./common/models/trading.fund.model";
import { NewFund } from "./common/models/new.fund.model";
import { FeatureOnAppPopupComponent } from "./common/components/feature-on-app-popup/feature-on-app-popup";
import { GuidePaymentQRPopupComponent } from "./common/components/guide-payment-QR-popup/guide-payment-QR-popup";
import { DetailNewFund } from "./product/components/detail-new-fund/detail-new-fund";
import { DetailTradingFund } from "./product/components/detail-trading-fund/detail-trading-fund";
/**
 * declare Jquery
 * */
declare let $: any;
declare let ga: any;
declare var require: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [AppSettings],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public Constant = Constant;
  public firstLoad: boolean = true;
  public isShowLoading: boolean = true;
  public settings: Settings;
  public eventSubscribers?: Subscription[] = [];
  ignoreApi: any[] = [];
  noLoadingApi: any[] = [];
  otp: any;
  product: any;
  userProfile: UserProfile;
  online$: Observable<boolean>;
  isOffline: boolean = false;
  isDevice: any = false;
  isLoading: any = false;
  minHeight: string = "1500px";
  tokenRobo: any;
  urlPathRoute: string;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: DialogService,
    private dispatcher: Dispatcher,
    public userService: UserService,
    private appSettings: AppSettings,
    private eventManager: EventManagerService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private productService: ProductService,
    private investorService: InvestorService,
    private themeService: ThemeService,
    private modalService: NgbModal,
    private appQueries: AppQueries,
    private accoutService: AccountService,
    private maintainceService: MaintainceService,
    private roboService: RoboService,
    public route: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;

    this.route.params.subscribe((params) => {
      if (location.search.substring(0, 14) === "?fb_action_ids") {
        location.search = "";
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.urlPathRoute = location.href;
      if (params.utm_source === "accesstrade" && params.aff_sid) {
        this.$localStorage.store("utm_source", params.utm_source);
        this.$localStorage.store("aff_sid", params.aff_sid);
      }
      if (params["thirdAppId"]) {
        this.$localStorage.store("thirdAppId", params["thirdAppId"]);
        this.$sessionStorage.store("thirdAppId", params["thirdAppId"]);
      }
    });
  }

  ngOnInit() {
    if (
      location.hostname !== "localhost" &&
      location.hostname !== "fma.dev.techland.link" &&
      location.hostname !== "fmarket.vn" &&
      location.hostname !== "temp.fma.dev.techland.link"
    ) {
      this.themeService.getTheme();
    } else {
      this.dispatcher.fire(
        new StoreTheme({
          logo: Constant.logo_url_logo_white,
          backgroundUrl: `url(${location.origin}${environment.baseHref}/assets/images/background/bg-default.png) no-repeat top`,
          textChange: {
            footer: "© FINCORP JSC 2018. All Rights Reserved",
          },
          isDefault: true,
        })
      );
      document.documentElement.style.setProperty("--background-url", `url(${environment.baseHref}/assets/images/background/bg-default.png) no-repeat top`);
    }
    // this.online$ = Observable.merge(
    //   Observable.of(navigator.onLine),
    //   Observable.fromEvent(window, "online").mapTo(true),
    //   Observable.fromEvent(window, "offline").mapTo(false)
    // );
    this.initIgnorepis();
    this.initMultipleApis();
    this.initApiNoLoading();
    setTimeout(() => {
      this.firstLoad = false;
    }, 5000);
    const token =
      this.$localStorage.retrieve("TOKEN") ||
      this.$sessionStorage.retrieve("TOKEN");
    const thirdAppId =
      this.$localStorage.retrieve("thirdAppId") ||
      this.$sessionStorage.retrieve("thirdAppId");
    if (token && (!thirdAppId || !environment.production)) {
      this.dispatcher.fire(new GetInfoUser(true));
    }
    this.dispatcher.fire(new GetDataSource());
    let scriptId = "google-analytics";
    this.checkWidthScreen();
    $(window).resize(() => {
      this.checkWidthScreen();
    });
    this.eventSubscribers = [
      this.eventManager.subscribe("change-language", (res) => {
        const language = res.content.language || "VI";
        const file = language === "VI" ? "vi_VN" : "en_US";
        // const file1 = language === "EN" ? "en_US" : "vi_VN";
        // this.translate.resetLang(file1);
        this.translate.use(file);
        localStorage.setItem(Constant.key_local_language, language);
        localStorage.setItem(Constant.key_local_language_file, file);
      }),
      this.appQueries.userProfile$.subscribe((userProfile) => {
        this.userProfile = userProfile;
      }),
      this.eventManager.subscribe("Fmarket.httpError", (res) => {
        const err = res.content;
        console.log("API error: ", err);
        const messsage = err.error.message || err.error.detail;
        if (err.status === 500) {
          this.dialog.showError('Hiện tại chức năng đang được cập nhật', '' , {iconImage: Constant.logo_500_API});
        } else if (!this.ignoreError(res.content.url || "")) {
          const text = window.navigator.onLine
            ? "Hiện tại chức năng đang được cập nhật"
            : "Có sự cố về kết nối mạng. Vui lòng kiểm tra lại.";
          this.dialog.showError(messsage || this.translate.instant(text));
        }
      }),
      this.eventManager.subscribe("Fmarket.maintainHttpError", (res) => {
        console.log("API error: ", res.content);
        const messsage = res.content.error.message || res.content.error.detail;
        if (!this.ignoreError(res.content.url || "")) {
          if (!window.navigator.onLine) {
            this.dialog.showNoConnectInternet();
          } else {
            if (!(this.userProfile && this.userProfile.id)) {
              this.router.navigate(["/503"]);
            } else {
              this.maintainceService.addAPI(res.content.message);
            }
          }
        }
      }),
      this.eventManager.subscribe("Fmarket.error", (res) => {
        const text = window.navigator.onLine
          ? "Hiện tại hệ thống đang gặp cập nhật. Vui lòng quay lại sau !"
          : "Có sự cố về kết nối mạng. Vui lòng kiểm tra lại.";
        this.dialog.showError(
          res.content.messsage || this.translate.instant(text)
        );
      }),
      this.eventManager.subscribe("detail-product", (res) => {
        this.detailProduct(res.content);
      }),
      this.eventManager.subscribe("detail-gold", (res) => {
        this.detailProduct(res.content);
      }),
      this.eventManager.subscribe("open-otp", (res) => {
        this.openOTP(res.content);
      }),
      this.eventManager.subscribe("show-spinner", (res) => {
        this.isShowLoading = true;
      }),
      this.eventManager.subscribe("close-spinner", (res) => {
        this.isShowLoading = false;
      }),
      this.eventManager.subscribe("get-token-robo", (res) => {
        res.content = res.content || {};
        this.getTokenRobo(res.content.isAgainCall);
      }),
      this.eventManager.subscribe("need-sign-contract", (res) => {
        this.showPopupNeedSignContract();
      }),
      this.eventManager.subscribe("need-sign-change-profile", (res) => {
        this.showPopupNeedSignChangeProfile();
      }),
      this.eventManager.subscribe("open-guide-payment-QR-popup", (res) => {
        this.openGuidePaymenmtQRPopup();
      }),
      this.eventManager.subscribe("go-to-deeplink", (res) => {
        this.goToAppStore(res.content.deeplink, res.content.isFeatureOnlyApp, res.content.isNoStoreApp);
      }),
      this.eventManager.subscribe("set-up-check-api", (res) => {
        if (!sessionStorage.getItem("multipleAPIs")) {
          this.initIgnorepis();
          this.initMultipleApis();
          this.initApiNoLoading();
        }
      }),
    ];
    this.translate.addLangs(["vi_VN", "en_US"]);
    let langFile =
      localStorage.getItem(Constant.key_local_language_file) || "vi_VN";
    this.translate.use(langFile);
    let language = localStorage.getItem(Constant.key_local_language) || "VI";
    this.eventManager.broadcast({
      name: "change-language",
      content: {
        language,
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoading = false;
        sessionStorage.setItem(Constant.key_local_current_screen, event.url);
        this.dialog.destroy();
      }
    });
    if (environment.production) {
      this.router.errorHandler = (error) => {
        if (navigator.onLine) {
          location.reload();
        } else {
          this.dialog.showError(
            this.translate.instant(
              "Máy chủ đang bảo trì, vui lòng thử lại sau!"
            )
          );
          this.isLoading = false;
        }
      };
    }
    if (document.getElementById(scriptId)) {
      return;
    }
    /* Scroll to active navbar tab */
    $(document).on("click", ".tab-scrollX .nav-link", function (e) {
      let tar = $(e.target).parents("li");
      let tarParent = tar.parents("ul");
      let x = tar.parents("body");
      tarParent.animate({
        scrollLeft:
          tar.offset().left + tarParent.scrollLeft() - x.width() / 1.5,
      });
    });

    /* Scroll to active navbar tab mobile */
    $(document).on("click", ".tab-scrollX-mobile .nav-link", function (e) {
      let tar = $(e.target).parents("li");
      let tarParent = tar.parents("ul");
      let x = tar.parents("body");
      tarParent.animate({
        scrollLeft:
          tar.offset().left + tarParent.scrollLeft() - tarParent.width() / 2,
      });
    });

    // Chatting
    let chatDiv = ".chat-container div";
    $(window).bind("scroll", function () {
      $(chatDiv).removeClass("ani");
    });

    $(document).on("click", ".phone", function () {
      $(chatDiv).toggleClass("ani");
    });

    $(document.body).on("hide.bs.modal", function () {
      $(".horizon-mobile").scrollTop(0);
    }); // Reset scroll mobile popup

    $(document.body).on("hidden.bs.modal", function () {
      $("body").css("padding-right", "0");
    });
    $(window).scroll((e) => {
      this.minHeight =
        e.target.body.clientHeight > 1500
          ? e.target.body.clientHeight + "px"
          : "1500px";
    });
    window.addEventListener("beforeunload", () => {
      const thirdAppId =
        this.$localStorage.retrieve("thirdAppId") ||
        this.$sessionStorage.retrieve("thirdAppId");
      if (thirdAppId && environment.production) {
        this.accoutService.logout();
      }
    });
  }
  ngOnDestroy(): void {}
  ngAfterViewInit(): void {
    $("body").on("click", (e) => {
      this.eventManager.broadcast({
        name: "body-click",
        content: {
          event: e,
        },
      });
    });
    this.listenChildrenMessage();
  }
  checkWidthScreen() {
    this.isDevice = $(window).width() < 480 ? true : false;
    this.eventManager.broadcast({
      name: "change-width-screen",
      content: {
        isDevice: this.isDevice,
      },
    });
  }
  noticeOffline() {
    if (this.isOffline) {
      return;
    }
    this.isOffline = true;
    this.dialog.showNoConnectInternet();
  }
  public changeTheme(theme) {
    this.settings.theme = theme;
  }
  openOTP(payload) {
    const otp: any = Object.assign({}, this.otp);
    payload = payload || {};
    const user = this.$localStorage.retrieve("USER_PROFILE") || {};
    const options: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    options.centered = true;
    options.windowClass = "otp-order-label";
    const modalRef = this.modalService.open(NewOTPComponent, options);
    const properties = payload.isReopen ? otp : payload;
    modalRef.componentInstance.phone = payload.phone || user.phone;
    Object.keys(properties || {}).forEach((key) => {
      modalRef.componentInstance[key] = properties[key] || {};
    });
    if (!payload.isReopen) {
      Object.keys(properties || {}).forEach((key) => {
        otp[key] = properties[key] || {};
      });
      otp.startTime = Date.now();
      otp.failTimes = 0;
      modalRef.componentInstance.startTime = otp.startTime;
    }
    this.otp = otp;
  }
  detailProduct(event) {
    if (!event) {
      return;
    }
    const id = event.productId || event.id;
    if (event.isProductIpo) {
      this.openProductModal(DetailIPOFund, {
        class: "detail-ipo-fund",
        isDetailFullPage: event.isDetailFullPage,
      });
    } else {
      switch (event.type) {
        case "NEW_FUND":
          this.openProductModal(DetailNewFund, {
            product: new NewFund(),
            class: "detail-new-fund",
            isDetailFullPage: event.isDetailFullPage,
          });
          break;
        case "TRADING_FUND":
          this.openProductModal(DetailTradingFund, {
            product: new TradingFund(),
            class: "detail-trading-fund",
            isDetailFullPage: event.isDetailFullPage,
          });
          break;
        case Constant.CODE_GOLD:
          this.openProductModal(DetailGold, {
            product: new Gold(),
            class: "detail-gold",
          });
          break;
      }
    }
    this.investorService.getProductById(id).subscribe((result: any) => {
      this.product = this.productService.parse(result.data);
      this.eventManager.broadcast({
        name: "response-get-detail-product",
        content: {
          product: this.product,
        },
      });
    });
  }
  getTokenRobo(isAgainCallAPI?) {
    if (
      !this.userProfile.totalDayInUse ||
      (!isAgainCallAPI && this.tokenRobo)
    ) {
      return;
    }
    return this.roboService.getToken().subscribe((res: any) => {
      const token = "Bearer " + res.data.token;
      this.$localStorage.store("ROBO_TOKEN", token);
      this.$sessionStorage.store("ROBO_TOKEN", token);
      this.tokenRobo = token;
      this.eventManager.broadcast({ name: "token-robo", content: { token } });
      if (isAgainCallAPI) {
        this.eventManager.broadcast({ name: "calculate-result-again" });
      }
    });
  }
  openProductModal(component, properties?) {
    properties = properties || {};
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass =
      (properties.isDetailFullPage
        ? "detail-fullscreen-fund-modal "
        : "detail-product-modal ") + (properties.class || "");
    const modalRef = this.modalService.open(component, options);
    modalRef.componentInstance.product = properties.product;
    modalRef.componentInstance.isDetailFullPage = properties.isDetailFullPage;
  }
  showPopupNeedSignContract() {
    if (!environment.production) {
      return;
    }
    const object =
      this.$localStorage.retrieve("object-need-sign-contract") || {};
    let time = object[this.userProfile.code];
    if (time) {
      const date = DateUtil.revertDate(time, "MM/dd/yyyy, hh:mm");
      const hour = (new Date().getTime() - date.getTime()) / (60 * 60 * 1000);
      // if (environment.production ? hour < 8 : hour < 0.25) {
      //   return;
      // }
    }
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = "confirm-popup-modal";
    const modalRef = this.modalService.open(ConfirmPopupComponent, options);
    modalRef.componentInstance.header = "Thông báo";
    modalRef.componentInstance.title =
      "Quý khách vui lòng ký Giấy ĐKGD để hoàn tất hồ sơ đầu tư.";
    modalRef.componentInstance.buttonLabel = "Ký ngay";
    modalRef.componentInstance.iconImage = "./assets/images/icon/ic-need-sign.svg";
    modalRef.componentInstance.func = (() => {
      this.router.navigate([Constant.route_register_trade]);
    }).bind(this);
    modalRef.componentInstance.cancelFunc = (() => {
      this.modalService.dismissAll();
    }).bind(this);
    object[this.userProfile.code] = DateUtil.parseDate(
      new Date(),
      "MM/dd/yyyy, hh:mm"
    );
    this.$localStorage.store("object-need-sign-contract", object);
  }
  showPopupNeedSignChangeProfile() {
    if (!environment.production) {
      return;
    }
    const object =
      this.$localStorage.retrieve("object-need-sign-change-profile") || {};
    let time = object[this.userProfile.code];
    if (time) {
      const date = DateUtil.revertDate(time, "MM/dd/yyyy, hh:mm");
      const hour = (new Date().getTime() - date.getTime()) / (60 * 60 * 1000);
      if (environment.production ? hour < 8 : hour < 0.25) {
        return;
      }
    }
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = "confirm-popup-modal";
    const modalRef = this.modalService.open(ConfirmPopupComponent, options);
    modalRef.componentInstance.header = "Thông báo";
    modalRef.componentInstance.title =
      "Quý khách vui lòng ký Giấy thay đổi thông tin để cập nhật lên Trung tâm lưu ký Chứng khoán.";
    modalRef.componentInstance.buttonLabel = "Ký ngay";
    modalRef.componentInstance.iconImage = "./assets/images/icon/ic-need-sign.svg";
    modalRef.componentInstance.func = (() => {
      this.router.navigate([Constant.route_profile_investor_need_sign]);
    }).bind(this);
    modalRef.componentInstance.cancelFunc = (() => {
      this.modalService.dismissAll();
    }).bind(this);
    object[this.userProfile.code] = DateUtil.parseDate(
      new Date(),
      "MM/dd/yyyy, hh:mm"
    );
    this.$localStorage.store("object-need-sign-change-profile", object);
  }
  goToAppStore(deeplink, isFeatureOnlyApp, isNoStoreApp: boolean = false) {
    if (Constant.checkAndroid() || Constant.checkIOS()) {
      
      setTimeout(
        () => {
          if (isNoStoreApp) {
            return;
          }
          const linkApp = Constant.checkAndroid()
            ? Constant.DEEPLINK_ANROID_APP
            : Constant.DEEPLINK_IOS_APP;
          window.location.href = linkApp;
        },
        Constant.checkAndroid() ? 2000 : 5000
      );
      window.location.href = deeplink;
    } else if (isFeatureOnlyApp) {
      this.openFeatureOnApp();
    }
  }
  openFeatureOnApp() {
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = "feature-on-app-modal";
    const modalRef = this.modalService.open(
      FeatureOnAppPopupComponent,
      options
    );
  }
  openGuidePaymenmtQRPopup() {
    const options: NgbModalOptions = {};
    options.centered = true;
    options.backdrop = false;
    options.windowClass = "guide-payment-QR-popup";
    const modalRef = this.modalService.open(
      GuidePaymentQRPopupComponent,
      options
    );
  }
  listenChildrenMessage() {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(
      messageEvent,
      (e) => {
        var key = e.message ? "message" : "data";
        var data = e[key];
        if (data === "viewMoreProduct") {
          this.router.navigate([Constant.path_products_home]);
        }
        //run function//
      },
      false
    );
  }
  initIgnorepis() {
    this.ignoreApi = [
      Constant.api_security_otp_confirm_phone_1,
      Constant.api_security_otp_confirm_phone_2,
      Constant.api_security_otp_confirm_investors_approve_payment,
      Constant.api_investor_export_transaction_history,
      Constant.api_common_print_investor_asset_export,
      Constant.api_security_otp_confirm_transfer_product,
      Constant.api_investors_otp_sell_confirm,
      Constant.api_security_otp_confirm_change_mail,
      Constant.api_investors_register_sip,
      Constant.api_security_otp_confirm_register_sip,
      Constant.api_security_otp_confirm_sign_up,
      Constant.api_investors_confirm_sign_document_change_profile,
      Constant.api_investors_esign_confirm,
      Constant.api_investors_add_cart,
      Constant.api_investors_sell,
      Constant.api_security_otp_transfer_product,
      Constant.api_investors_estimate_sell_fund,
      Constant.api_investor_get_target_fund,
      Constant.api_investors_estimate_transfer_fund,
      Constant.api_investors_estimate_sell_fund,
      Constant.api_security_otp_update_step_3,
      Constant.api_investors_buy_gold,
      Constant.api_investors_sell_gold,
      Constant.api_investors_egold_open,
      Constant.api_investors_egold_link,
      Constant.api_investors_get_buy_product_gold,
      Constant.api_investors_withdraw_gold,
      Constant.api_robo_get_token,
      Constant.api_user_validate_ekyc,
      Constant.api_investors_load_list_assets,
      Constant.api_investors_load_chart_asset,
      Constant.api_user_check_times_ekyc,
      Constant.api_user_profile_ekyc,
      Constant.api_investors_confirm_sign_ipo,
      "dest-products",
      "dest-programs",
      Constant.api_investors_check_esign_before_buy,
      Constant.api_security_otp_validate,
      Constant.api_investors_get_province_withdraw_gold,
      Constant.api_investors_get_district_withdraw_gold,
      Constant.api_investors_get_time_withdraw_gold,
      Constant.api_investors_get_district_withdraw_gold,
      Constant.api_investors_load_contribute_goal_orders,
      Constant.api_ocr_step_1_ekyc,
      Constant.api_ocr_step_2_ekyc
    ];
  }
  initMultipleApis() {
    const multipleAPIs = {};
    multipleAPIs[Constant.api_common_products_filter] = true;
    multipleAPIs[Constant.api_investors_orders_pending] = true;
    multipleAPIs[Constant.api_investors_load_list_assets] = true;
    multipleAPIs[Constant.api_investors_assets_management] = true;
    multipleAPIs[Constant.api_investor_transaction_history] = true;
    multipleAPIs[Constant.api_path_upload_file] = true;
    multipleAPIs[Constant.api_get_data_chart] = true;
    multipleAPIs[Constant.api_investors_load_chart_asset] = true;
    multipleAPIs["estimate"] = true;
    multipleAPIs["filter"] = true;
    multipleAPIs["summary"] = true;
    multipleAPIs["/investors/sip/list"] = true;
    sessionStorage.setItem("multipleAPIs", JSON.stringify(multipleAPIs));
  }
  initApiNoLoading() {
    this.noLoadingApi = [
      { url: Constant.api_user_profile, method: "GET" },
      {
        url: Constant.api_version_2 + Constant.api_investors_load_list_assets,
        method: "",
      },
      { url: Constant.api_common_products_id, method: "GET" },
    ];
    sessionStorage.setItem("noLoadingAPIs", JSON.stringify(this.noLoadingApi));
  }
  ignoreError(url) {
    return this.ignoreApi.some((api) => url.includes(api));
  }
  ignoreLoading(url) {
    return this.noLoadingApi.some((api) => url.includes(api));
  }
}
