import { environment } from "../../environments/environment";

export class Constant {
  //Header config
  public static header_config_code = "Fmarket";
  public static site_key_recaptcha = environment.capcha;
  public static account_firebase = "lhphuong86.finanma@gmail.com";
  public static password_firebase = "finandesk-firebase";
  public static key_root_product_firebase = "/product/";
  //URL
  public static url_host = environment.urlBackEnd;
  public static url_asset = environment.asset;
  public static url_new_fmarket = environment.urlNewFmarket;
  public static url_host_upload = environment.urlUploadBackEnd;
  public static url_facebook = "https://www.facebook.com/fmarket";
  public static url_twitter = "https://twitter.com/fmarket";
  public static url_youtube = "https://youtube.com/fmarket";

  //TODO Icon s3
  public static icon_url_images = environment.baseHref + "/assets/images/";
  public static icon_url_local_host =
    environment.asset + "/assets/images/icon/";
  public static icon_url_host = environment.baseHref + "/assets/images/icon/";
  public static background_image_url = environment.baseHref + "/assets/images/background/";
  public static images_url_host = environment.asset + "/assets/images/";
  public static logo_url_host = environment.baseHref + "/assets/images/logo/";
  public static icon_503_host = environment.baseHref + "/assets/images/503/";
  public static icon_url_pre = Constant.icon_url_host + "arrow-l.png";
  public static icon_url_next = Constant.icon_url_host + "arrow-r.png";
  public static icon_url_convert = Constant.icon_url_host + "arrow.svg";
  public static icon_url_pdf = Constant.icon_url_host + "pdf.svg";
  public static icon_url_pdf_icon = Constant.icon_url_host + "pdf_icon.png";
  public static icon_url_excel = Constant.icon_url_host + "excel.svg";
  public static icon_url_xls_icon = Constant.icon_url_host + "xls_icon.png";
  public static icon_url_no_data = Constant.icon_url_host + "nodata.png";
  public static icon_url_add = Constant.icon_url_host + "add.svg";
  public static icon_url_remove = Constant.icon_url_host + "delete.svg";
  public static icon_url_remove_table = Constant.icon_url_host + "delete-table.svg";
  public static icon_url_pencil = Constant.icon_url_host + "butchi.svg";
  public static icon_url_copy = Constant.icon_url_host + "copy.svg";
  public static icon_url_notice = Constant.icon_url_host + "notice-large.svg";
  public static icon_url_notice_bg = Constant.icon_url_host + "notice-bg.svg";
  public static icon_url_pay_ment = Constant.icon_url_host + "thanh-toan.svg";
  public static icon_url_files = Constant.icon_url_host + "files.svg";
  public static icon_url_files_active = Constant.icon_url_host + "files-active.svg";
  public static icon_url_user = Constant.icon_url_host + "user.svg";
  public static icon_url_user_id = Constant.icon_url_host + "iconID.svg";
  public static icon_url_language = Constant.icon_url_host + "ic-language.svg";
  public static icon_url_fail = Constant.icon_url_host + "error.svg";
  public static icon_url_fails = Constant.icon_url_host + "link-fail.png";
  public static icon_url_success = Constant.icon_url_host + "success-bg.svg";
  public static icon_url_white_success = Constant.icon_url_host + "success.svg";
  public static icon_url_close_modal = Constant.icon_url_host + "close-x.png";
  public static icon_url_minimisize = Constant.icon_url_host + "minisize.png";
  public static icon_url_toolchat = Constant.icon_url_host + "callme.png";
  public static icon_url_viewmore = Constant.icon_url_host + "viewmore.svg";
  public static icon_url_twitter = Constant.icon_url_host + "TWITTER.svg";
  public static icon_url_viber = Constant.icon_url_host + "VIBER.svg";
  public static icon_url_skype = Constant.icon_url_host + "SKYPE.svg";
  public static icon_url_messenger = Constant.icon_url_host + "MESSENGER.svg";
  public static icon_files = Constant.icon_url_host + "files.svg";
  public static icon_down = Constant.icon_url_host + "download.png";
  public static icon_user = Constant.icon_url_host + "user.svg";
  public static icon_check = Constant.icon_url_host + "check.svg";
  public static icon_url_fa_mail = Constant.icon_url_host + "icon-mail.svg";
  public static icon_url_fa_marker = Constant.icon_url_host + "icon-marker.svg";
  public static icon_url_fa_phone = Constant.icon_url_host + "icon-phone.svg";
  public static icon_url_gold_demo = Constant.icon_url_host + "vang-demo.png";
  public static icon_url_avatar = Constant.icon_url_host + "ic-avatar.svg";
  public static icon_url_default_user = Constant.icon_url_host + "ic-default-user.png";
  public static icon_url_no_image = Constant.icon_url_host + "No-Image.png";
  public static logo_no_connect_internet = Constant.icon_503_host + "Disconnect.png";
  public static logo_500_API = Constant.icon_503_host + "image-tinhnangbiloi.png";
  // background image
  public static logo_front_card =  Constant.background_image_url + "front-card.png";
  public static logo_back_card = Constant.background_image_url + "back-card.png";
  

  //user code
  public static code_investor = "investor";
  public static CODE_INVESTOR = "INVESTOR";
  public static CODE_INVESTOR_PERSONAL = "INVESTOR_PERSONAL";
  public static CODE_INVESTOR_ORGANIZATION = "INVESTOR_ORGANIZATION";
  public static code_issuer = "issuer";
  public static CODE_ISSUER = "ISSUER";
  public static CODE_AGENT = "AGENT";

  //user status code
  public static status_first_login = "USER_FIRST_LOGIN";
  public static status_user_pending = "USER_PENDING";
  public static STATUS_PRODUCT_PENDING = "PRODUCT_PENDING";
  public static STATUS_PRODUCT_APPROVED = "PRODUCT_APPROVED";
  public static STATUS_PRODUCT_DRAFT = "PRODUCT_DRAFT";

  //path
  public static path_dashboard = "dashboard";
  public static path_fund = "fund";
  public static path_gold = "gold";
  public static path_old_home = "home-dashboard";
  public static path_home = "home";
  public static path_resource = "res";
  public static path_issuers = "issuers";
  public static path_general = "general";
  public static path_support = "support";
  public static path_contact = "contact";
  public static path_promotion = "promotion";
  public static path_aboutUs = "aboutUs";
  public static path_calculate_invest = "ftool";
  public static path_performance_fund = "hieu-suat-quy-mo";
  public static path_bank_interest_rate = "lai-suat-ngan-hang";
  public static path_bank_interest_rate_old = "bank-interest-rate";
  public static path_blog_page = "blog/";
  public static path_fmarketPlatform = "fmarketPlatform";
  public static path_how_it_work = "howItWork";
  public static path_introduce_agency = "refpartner";
  public static path_detail_portfolio = "portfolio";
  public static path_blog = "blog";
  public static path_type = "type";
  public static path_knowledge = "knowledge";
  public static path_story = "story";
  public static path_news = "news";
  public static path_detail_blog = "detail";
  public static path_page_blog = "page";
  public static path_products = "products";
  public static path_products_home = "san_pham";
  public static path_fund_page = "danh-sach-quy";
  public static path_gold_home = "danh-sach-vang";
  public static path_detail_products_home = "funds";
  public static path_detail_product_home = "chart";
  public static path_legal = "legal";
  public static path_term = "term";
  public static path_our_story = "our-story";
  public static path_our_partner = "our-partner";
  public static path_partner = "doi-tac";
  public static path_account = "account";
  public static path_auth = "auth";
  public static path_login = "login";
  public static path_introduce_app = "introduce-app";
  public static path_ekyc = "ekyc";
  public static path_passport_ocr = "passport-ocr";
  public static path_login_success = "login-success";
  public static path_forgot = "forgot";
  public static path_profile = "profile";
  public static path_private_information = "private-information";
  public static path_register_trade = "register-trade";
  public static path_survey_ipo = "survey-ipo";
  public static path_roboF = "robo";
  public static path_change_pass = "change-password";
  public static path_change_email = "change-email";
  public static path_change_phone = "change-phone";
  public static path_delete_account = "delete-account";
  public static path_referral_code = "referral-code";
  public static path_first_login = "required";
  public static path_active = "activation";
  public static path_share = "share";
  public static path_change = "change";
  public static path_active_success = "success";
  public static path_active_fail = "fail";
  public static path_change_mail_success = "change-mail/success";
  public static path_change_mail_fail = "change-mail/fail";
  public static path_active_reset_pass = "reset-password";
  public static path_register = "register";
  public static path_register_investor = "signUp";
  public static path_register_issuer = "signUp/" + Constant.code_issuer;
  public static path_contribute_goal = "contribute-goal";
  public static path_contribute_goal_notice = "notice";
  public static path_contribute_goal_order = "order";
  //Path investor
  public static path_market = "market";
  public static path_trading = "trading";
  public static path_mismatch_order = "mismatched_order";
  public static path_transaction_management = "transaction";
  public static path_asset_management = "asset";
  public static path_report = "report";
  public static path_help = "help";
  public static path_smart_portfolio = "smart-portfolio";
  public static path_main_profile = "main-profile";
  //Path issuer
  public static path_transaction = "transaction";
  public static path_issuer_report = "report";

  //Path agen
  public static code_agent = "agent";

  //Tab investor market
  public static tab_market = "fund";
  public static tab_market_fund = "fund";
  public static tab_market_gold = "gold";
  public static tab_trading_order = "trading-order";
  public static tab_transaction_history = "transaction-history";

  
  //Tab money transaction
  public static tab_cash_statement = "cash-statement";
  public static tab_deposit_guide = "deposit-guide";

  //Tabs profile
  public static tab_private_information = "private-information";
  public static tab_private_information_step_1 = "PRIVATE";
  public static tab_private_information_step_2 = "DOCUMENT";
  public static tab_private_information_step_3 = "BANK";
  public static tab_private_information_step_4 = "FATCA";
  public static tab_second_profile = "second-profile";
  public static tab_sign_document = "sign-document";
  public static tab_register_trade = "register-trade";
  public static tab_information_profile = "information-profile";
  public static tab_bank_profile = "bank-profile";
  public static tab_passport_profile = "passport-profile";
  public static tab_phone_profile = "phone-profile";
  public static tab_mail_profile = "mail-profile";

  //Tabs create
  public static tab_asset_detail_holding = "detail_holding";
  public static tab_asset_tab_category_investor = "category_investor";
  public static tab_trading_tab_recurring_investment = "recurring_investment";
  public static tab_mismatched_order = "mismatched_order";

  //Support
  public static tab_support_kn = "knowledge";
  public static tab_support_qa = "faq";
  public static tab_support_ua = "useApp";

  //legal
  public static tab_legal_terms = "terms";
  public static tab_legal_privacy = "privacy";
  public static tab_legal_disclosures = "disclosures";

  //Route Url
  public static url_home = Constant.url_new_fmarket + Constant.path_home;
  public static url_resource = "/" + Constant.path_resource;
  public static url_support = "/" + Constant.path_support;
  public static url_support_qa = "/" + Constant.path_support + "?tab=faq";
  public static url_contact = Constant.url_new_fmarket + Constant.path_contact;
  public static url_fund_home =  Constant.url_new_fmarket + Constant.path_fund_page;
  public static url_gold_home = Constant.url_new_fmarket + Constant.path_gold_home;
  public static url_legal = Constant.url_new_fmarket + Constant.path_legal +  "/";
  public static url_legal_term = Constant.url_legal +  Constant.tab_legal_terms;
  public static url_legal_privacy = Constant.url_legal + Constant.tab_legal_privacy;
  public static url_our_story = Constant.url_new_fmarket + Constant.path_our_story;
  public static url_our_partner = Constant.url_new_fmarket + Constant.path_our_partner;
  public static url_partner = Constant.url_new_fmarket + Constant.path_partner + '/';
  public static url_fmarketPlatform = Constant.url_new_fmarket + Constant.path_fmarketPlatform;

  public static url_investor = "/" + Constant.path_account + "/" + Constant.code_investor + "/";
  public static url_reset_pass = "/" + Constant.path_active + "/" + Constant.path_active_reset_pass;
  public static url_active_mail_success =  "/" + Constant.path_active + "/" + Constant.path_active_success;
  public static url_active_mail_fail = "/" + Constant.path_active + "/" + Constant.path_active_fail;
  public static url_change_mail_success =
    "/" + Constant.path_active + "/" + Constant.path_change_mail_success;
  public static url_change_mail_fail =
    "/" + Constant.path_active + "/" + Constant.path_change_mail_fail;
  public static url_forgot =
    "/" + Constant.path_auth + "/" + Constant.path_forgot;
  public static url_login =
    "/" + Constant.path_auth + "/" + Constant.path_login;
  public static url_register_investor =
    "/" + Constant.path_auth + "/" + Constant.path_register_investor;
  public static url_register_issuer =
    "/" + Constant.path_auth + "/" + Constant.path_register_issuer;
  public static url_first_login =
    "/" + Constant.path_auth + "/" + Constant.path_first_login;
  public static url_investor_profile = Constant.url_investor + Constant.path_profile + "/";
  public static url_investor_main_profile = Constant.url_investor_profile + Constant.path_main_profile;

  public static url_investor_main_profile_private_information = Constant.url_investor_main_profile + '/' + Constant.path_private_information;

  //URL screen
  public static url_investor_register_trade = Constant.url_investor + Constant.path_register_trade;
  public static url_investor_market = Constant.url_investor + Constant.path_market;
  public static url_investor_trading = Constant.url_investor + Constant.path_trading + '/';
  public static url_investor_trading_mismatch_order = Constant.url_investor_trading + Constant.path_mismatch_order;
  public static url_investor_transaction = Constant.url_investor_trading + Constant.path_transaction_management;
  public static url_investor_asset = Constant.url_investor + Constant.path_asset_management;
  public static url_investor_smart_portfolio = Constant.url_investor + Constant.path_smart_portfolio;
  public static url_investor_report = Constant.url_investor + Constant.path_report;
  public static url_investor_help = Constant.url_investor + Constant.path_help;
  public static url_investor_buy_manulife =  Constant.url_investor + "buy-manulife";
  public static route_market = Constant.url_investor_market + "/" + Constant.path_fund;
  public static route_market_gold =  Constant.url_investor_market + "/" + Constant.path_gold;
  public static route_trading = Constant.url_investor_trading + "/" + Constant.tab_trading_order;
  public static route_transaction_history = Constant.url_investor_trading + "/" + Constant.tab_transaction_history;
  public static route_transaction_history_fund = Constant.route_transaction_history + "/" + Constant.path_fund;
  public static route_transaction_history_gold = Constant.route_transaction_history + "/" + Constant.path_gold;
  public static route_register_sip = Constant.url_investor_trading +  Constant.tab_trading_tab_recurring_investment;
  public static route_register_trade = Constant.url_investor_register_trade;
  public static route_asset = Constant.url_investor_asset;
  public static route_roboF = Constant.url_investor +  "robo";
  public static route_reward = Constant.url_investor + "reward";
  public static route_profile_investor = Constant.url_investor_main_profile;
  public static route_profile_investor_need_sign = Constant.url_investor_main_profile + "/" + Constant.tab_sign_document;
  public static route_ekyc = "/auth/ekyc";
  public static route_change_password =
    Constant.url_investor_profile + Constant.path_change_pass;
  public static route_change_email =
    Constant.url_investor_profile + Constant.path_change_email;
  public static route_change_phone =
    Constant.url_investor_profile + Constant.path_change_phone;
  public static route_delete_account =
    Constant.url_investor_profile + Constant.path_delete_account;
  public static route_referral_code =
    Constant.url_investor_profile + Constant.path_referral_code;
  public static url_contribute_goal_notice =
    "/" +
    Constant.path_share +
    "/" +
    Constant.path_contribute_goal +
    "/" +
    Constant.path_contribute_goal_notice;
  //Key localStorage
  public static key_local_token = "token";
  public static key_local_version = "version";
  public static key_local_language = "language";
  public static key_local_language_file = "language-file";
  public static key_local_status = "status";
  public static key_local_first_login = "firstLogin";
  public static key_local_email = "emailFmarket";
  public static key_local_user_type = "type";
  public static key_local_login_sucess = "loginSuccess";
  public static key_local_current_screen = "currentScreen";
  public static key_local_current_create = "create";
  public static key_local_common_data = "common-data";
  public static key_local_cms = "cms";
  //Data type
  public static data_type_languages = "languages";
  public static data_type_countries = "countries";

  //Error code
  public static error_force_logout = 401;
  public static error_not_connect_server = 0;
  public static error_server_error = 500;
  public static error_server_maintenance = 503;
  public static error_code = 400;

  //Success code
  public static success_code = 200;
  public static ERROR_CODE_RENEW_PASSWORD = 40027;

  //Default variable
  public static default_language = "vi_VN";
  //API key upload
  public static clientApiKey = "clientApiKey";
  public static clientApiKey_value = "FMARKET-ANGULAR";
  public static clientApiKey_value_report = "FMARKET-ANGULAR-KEY2";
  public static requestId = "requestId";
  public static fileType = "fileType";
  public static photoType = "photoType";
  public static base64File = "base64File";
  public static fileTypeNormal = "FILE";
  public static fileTypePhoto = "PHOTO";
  public static applyOriginalFileName = "applyOriginalFileName";

  //API
  // OTP
  public static api_security_otp_validate = "/auth/sign-up-otp/step-1/validate";
  public static api_security_otp_sign_up = "/auth/sign-up-otp/create";
  public static api_security_otp_confirm_sign_up = "/auth/sign-up-otp/confirm";
  public static api_security_otp_change_mail =
    "/users/change-email-otp/step1/create";
  public static api_security_otp_confirm_change_mail =
    "/users/change-email-otp/step1/confirm";
  public static api_security_otp_confirm_change_passport =
    "/users/change-passport/step1/confirm";
  public static api_security_otp_change_phone =
    "/users/change-phone/step1/create";
  public static api_security_otp_update_step_3 =
    "/users/profile/step-3/confirm";
  public static api_security_otp_confirm_phone_1 =
    "/users/change-phone/step1/confirm";
  public static api_security_otp_confirm_phone_2 =
    "/users/change-phone/step2/confirm";
  public static api_security_otp_withdraw = "/users/withdrawal-otp/create";
  public static api_security_otp_confirm_withdraw =
    "/users/withdrawal-otp/confirm";
  public static api_security_otp_withdraw_issuer =
    "/products/{productId}/cash/withdrawal-otp/create";
  public static api_security_otp_withdraw_issuer_noproduct =
    "/products/cash/withdrawal-otp/create";
  public static api_security_otp_confirm_withdraw_issuer =
    "/products/{productId}/cash/withdrawal-otp/confirm";
  public static api_security_otp_confirm_withdraw_issuer_noproduct =
    "/products/cash/withdrawal-otp/confirm";
  public static api_security_otp_investors_approve_payment_one_step =
    "/investors/cart/approve-payment-one-step";
  public static api_security_otp_investors_approve_payment =
    "/investors/cart/approve-payment-otp/create";
  public static api_security_otp_confirm_investors_approve_payment =
    "/investors/cart/approve-payment-otp/confirm";
  public static api_security_otp_transfer_product =
    "/investors/orders/transfer-otp/create";
  public static api_security_otp_confirm_transfer_product =
    "/investors/orders/transfer-otp/confirm";
  public static api_security_otp_resend = "/otp/retry-otp";

  // API
  public static api_auth_sign_up = "/auth/sign-up";
  public static api_auth_sign_in = "/auth/login";
  public static api_auth_log_out = "/users/logout";
  public static api_auth_sign_in_confirm = "/auth/login/confirm";
  public static api_auth_forgot_pass = "/auth/forgot-password";
  public static api_auth_renew_registration_confirm =
    "/auth/resend-active-mail";
  public static api_security_change_mail_1 =
    "/users/change-email1/step1/create";
  public static api_security_change_mail_2 =
    "/users/change-email2/step1/create";
  public static url_investor_change_passport =
    "/users/change-passport/step1/create";
  public static url_investor_change_passport_one_step =
    "/users/change-passport/one-step";
  public static api_auth_reset_pass = "/auth/reset-password";
  public static api_user_sign_out = "/users/logout";
  public static api_user_first_sign_in = "/users/first-login";
  public static api_user_profile = "/users/profile";
  public static api_user_get_flag_update_name = "/users/flag-popup";
  public static api_user_update_name = "/users/profile/confirm-update-name";
  public static api_user_profile_ekyc = "/users/profile-ekyc";
  public static api_user_second_profile =
    "/users/profile/second-contact-person";
  public static api_user_change_pass = "/users/change-password";
  public static api_user_add_bank_account =
    "/users/bank-accounts/addbankacc-otp/create";
  public static api_user_add_bank_account_confirm =
    "/users/bank-accounts/addbankacc-otp/confirm";
  public static api_user_edit_bank_account =
    "/users/bank-accounts/editbankacc-otp/create";
  public static api_user_edit_bank_account_confirm =
    "/users/bank-accounts/editbankacc-otp/confirm";
  public static api_user_delete_bank = "/users/bank-accounts/"; //TODO /users/bank-account/{bankAccountId}
  public static api_user_transactions_history_detail =
    "/transactions/history/detail";
  public static api_user_transactions_history_detail_fund =
    "/transactions/history/detail/fund";
  public static api_user_cash_of_statement =
    "/transactions/cash-statements/filter";
  public static api_user_get_verify_code = "/users/get-email-verification-code";
  public static api_user_check_email_verification =
    "/users/check-email-verification-code";
  public static api_user_update_profile = "/users/profile";
  public static api_user_update_avatar = "/users/avatar";
  public static api_user_update_photos = "/users/passport-photos";
  public static api_user_update_mail = "/users/request-change-email";
  public static api_user_report_all_document = "/reports/funds";
  public static api_user_report_document_1 = "/reports/products/";
  public static api_user_report_document_2 = "/documents/filter";
  public static api_user_report_send_mail =
    "/reports/products/{productId}/broastcast";
  public static api_user_social = "/reports/socials/"; //TODO ID;
  public static api_update_about_us = "/users/about-us"; //TODO ID;
  public static api_user_transaction_history =
    "/transactions/statements/filter";
  public static api_user_support_information = "/res/support-information";
  public static api_user_deposit_coutry = "/res/admin-countries";
  public static api_user_deposit_bank = "/res/admin-banks";
  public static api_user_validate_ekyc = "/users/ekyc/validate-result-ekyc";
  public static api_user_check_times_ekyc = "/users/ekyc/check-info-before-ekyc";
  public static api_ocr_step_1_ekyc = "/users/passport/front-verify";
  public static api_ocr_step_2_ekyc = "/users/passport/back-verify";
  public static api_ocr_step_3_ekyc = "/users/passport/ocr";
  public static api_version = "/";
  public static api_version_2 = "/v2";
  public static api_version_2_1 = "/v2.1";
  public static api_common = "/res/all";
  public static api_common_translations = "/res/translations?language= ";
  public static api_common_issuer = "/res/issuers";
  public static api_common_bond_issuer = "/res/bond-issuers";
  public static api_common_cd_issuer = "/res/cd-issuers";
  public static api_common_countries_market = "/res/markets";
  public static api_common_print_infor_product = "/res/products/print"; //TODO : ?productCode=170911240018
  public static api_common_print_investor_asset_export =
    "/investors/assets/export"; //TODO : ?type=PDF&fromDate=1501520400000&toDate=1506704400000
  public static api_common_print_transaction_management =
    "/transactions/statements/export"; //TODO : ?type=PDF&fromDate=1501520400000&toDate=1506704400000
  public static api_common_asset_fund_export = "/investors/assets/fund-export";
  public static api_common_issuer_top = "/res/issuers/top";
  public static api_common_contact = "/home/contact-us";
  public static api_common_text_home = "/res/cms-block";
  public static api_common_social_type = "/res/social-types";
  public static api_common_document_file_investor = "/users/documents/"; //TODO productId;
  public static api_common_following = "/home/subscribe";
  public static api_common_calcualte_invest = "/tools/profit-investment";
  public static api_common_unfollowing = "/reports/unsubscribe/{issuerId}";
  public static api_common_bond_following = "/home/bond-issuer-subscribe";
  public static api_common_bond_unfollowing =
    "/reports/bond-issuer-unsubscribe/{issuerId}";
  public static api_common_countries = "/res/countries";
  public static api_common_products_filter = "/res/products/filter";
  public static api_common_products_home_filter = "/res/products/home/filter";
  public static api_common_products_id = "/res/products/";
  public static api_get_report_products_filter = "/reports/products/filter";
  public static api_investor_get_banks = "/users/investors/deposit-guide/banks";
  public static api_investor_get_banks_account =
    "/users/investors/deposit-guide/bank-accounts";
  public static api_investor_get_bank_by_id =
    "/users/investors/deposit-guide/banks";

  //Investor
  public static api_investors_load_list_assets = "/investors/assets/fund";
  public static api_investors_load_chart_asset = "/investors/assets/proportion";
  public static api_investors_load_statistic_asset =
    "/investors/assets/statistics";
  public static api_investors_assets_management = "/investors/assets/filter";
  public static api_investors_recurring_management = "/investors/sip/list";
  public static api_investors_detail_recurring_management =
    "/investors/sip/order/history";
  public static api_investors_total_assets_management =
    "/investors/assets/summary";
  public static api_investors_get_total_transfer_product =
    "/investors/assets/product-program-summary";
  public static api_investors_check_over_current_session =
    "/investors/cart/check-over-current-session";
  public static api_investors_check_confirm_prospectus =
    "/investors/check-confirm-prospectus";
  public static api_investors_orders_pending =
    "/investors/orders/pending/filter";
  public static api_investors_orders_pending_detail = "/investors/orders/";
  public static api_investors_sell = "/investors/orders/sell-otp/create";
  public static api_investors_otp_sell_confirm =
    "/investors/orders/sell-otp/confirm";
  public static api_investors_trading_order =
    "/investors/orders/pending/filter";
  public static api_investors_cancel_order = "/investors/orders/cancel";
  public static api_investors_buy_product = "/investors/orders/buy";
  public static api_investors_check_register = "/investors/cart/check-buying";
  public static api_bond_remain_period = "/res/bond-remain-period/filter";
  public static api_cd_remain_period = "/res/cd-period/filter";
  public static api_investors_check_total = "/investors/cart/summary";
  public static api_investors_register_sip = "/investors/sip/register/create";
  public static api_security_otp_confirm_register_sip =
    "/investors/sip/register/confirm";
  public static api_investors_add_cart =
    "/investors/orders/create-buy-order-and-profile";
  public static api_investors_update_cart = "/investors/cart/update";
  public static api_investors_remove_cart = "/investors/cart/delete";
  public static api_investors_update_amount_SIP = "/investors/sip/update";
  public static api_investors_ignore_payment = "/investors/cart/ignore-payment";
  public static api_investors_confirm_prospectus =
    "/investors/confirm-prospectus";
  public static api_investors_estimate_sell_fund =
    "/investors/orders/estimate-sell-order";
  public static api_investors_estimate_transfer_fund =
    "/investors/orders/estimate-transfer-order";
  public static api_get_investment_profile_fund =
    "/investors/investment-profiles/fund/filter";
  public static api_get_investment_profile_bond =
    "/investors/investment-profiles/bond/filter";
  public static api_get_investment_profile_cd =
    "/investors/investment-profiles/cd/filter";
  public static api_get_investment_profile_approved =
    "/agents/investment-profiles/approved/filter";
  public static api_get_investment_profile_pending =
    "/agents/investment-profiles/pending/filter";
  public static api_get_investor_profile_pending =
    "/agents/investor-profile/pending/filter";
  public static api_investors_list = "/agents/products/asset/filter";
  public static api_agencies_register_trade = "/users/get-agents-by-issuer";
  public static api_register_agency = "/investors/investment-profiles/register";
  public static api_sign = "/investors/esign/start";
  public static api_cancel_profile_trade =
    "/investors/investment-profiles/unregister";
  public static api_investor_transaction_history =
    "/investors/transaction-history/filter";
  public static api_investor_export_transaction_history =
    "/investors/transaction-history/export";
  public static api_investor_get_target_fund =
    "/investors/transfer/{productId}/{schemeID}/{programId}/dest-products";
  public static api_investor_get_target_program =
    "/investors/transfer/{productId}/{schemeId}/{programId}/{destProductId}/dest-programs";
  public static api_investor_refCode = "/users/add-refcode";
  public static api_investors_get_list_sign_document =
    "/users/change-personal-info/list";
  public static api_investors_sign_document_change_profile =
    "/investors/confirm-change-info/sign/create";
  public static api_investors_confirm_sign_document_change_profile =
    "/investors/confirm-change-info/sign/confirm";
  public static api_investors_step_2_esign_create = "/users/profile/v2/step-2/esign/create";
  public static api_investors_step_2_esign_confirm = "/users/profile/v2/step-2/esign/confirm";
  public static api_investors_step_3_esign_create = "/users/profile/v2/step-3/esign/create";
  public static api_investors_step_3_esign_confirm = "/users/profile/v2/step-3/esign/confirm";
  public static api_investors_esign_create = "/investors/esign/sign/create";
  public static api_investors_esign_confirm = "/investors/esign/sign/confirm";
  public static api_investors_sign_change_name = "/investors/esign/sign-change-name/v2/confirm";
  public static api_investors_sign_change_confirm = "/investors/esign/sign-change-name/confirm";
  public static api_investors_get_buy_product_gold =
    "/investors/egold/buy-info/";
  public static api_investors_buy_gold = "/investors/cart/add";
  public static api_investors_egold_link = "/investors/egold-account/link";
  public static api_investors_egold_open = "/investors/egold-account/open";
  public static api_investors_egold_update_account =
    "/investors/egold/add-egold-info";
  public static api_investors_egold_get_bank = "/investors/egold/bank-list";
  public static api_investors_get_province_withdraw_gold =
    "/investors/egold/withdraw/schedule-city";
  public static api_investors_get_district_withdraw_gold =
    "/investors/egold/withdraw/schedule-district";
  public static api_investors_get_shop_withdraw_gold =
    "/investors/egold/withdraw/schedule-shop";
  public static api_investors_get_time_withdraw_gold =
    "/investors/egold/withdraw/schedule-time";
  public static api_investors_withdraw_gold = "/investors/orders/withdraw-gold";
  public static api_investors_cancel_withdraw_gold =
    "/investors/orders/cancel-withdraw-gold";
  public static api_investors_get_sell_product_gold =
    "/investors/egold/sell-info/";
  public static api_investors_sell_gold = "/investors/orders/sell-gold";
  public static api_investors_sign_ipo =
    "/investors/esign/sign-document-ipo/create";
  public static api_investors_confirm_sign_ipo =
    "/investors/esign/sign-document-ipo/confirm";
  public static api_investors_get_list_survey_ipo =
    "/investors/document-survey-ipo/filter";
  public static api_investors_get_reward = "/v2/investors/reward";
  public static api_investors_get_qr_code_reward = "/tools/generate-qr";
  public static api_investors_get_reward_introduce_investor =
    "/v2/investors/reward/new-user-introduce";
  public static api_investors_check_esign_before_buy =
    "/investors/orders/check-esign-before-buy";
  public static api_investors_update_fatca = "/users/update-fatca-info";
  public static api_investors_confirm_investor_type = "/investors/investor-classification";
  public static api_path_upload_file = "/file/upload";
  public static api_get_data_chart = "/res/product/get-nav-history";
  public static api_add_referral_code = "/users/add-referral-code";
  public static api_investor_save_additional_info = "/investors/save-additional-info";
  public static api_delete_account = "/users/delete-account/create";
  public static api_confirm_delete_account = "/users/delete-account/confirm";
  public static api_investors_request_refund =
    "/investors/create-refund-request";
  public static api_investors_load_list_reject_orders =
    "/investors/orders/reject/filter";
  public static api_investors_load_contribute_goal_orders =
    "/user-goal/auth/companion/order/get-saving";
  public static api_investors_confirm_payment_contribute_goal_orders =
    "/user-goal/auth/companion/order/approve-payment";
  public static api_investors_confirm_contribute_goal_orders =
    "/user-goal/auth/companion/confirm";

  // ROBO
  public static api_robo_get_token = "/investors/robo/token/create";
  public static api_robo_success_survey = "/investors/robo/process/success";
  public static api_robo_load_resource = "robo-advisor/load";
  public static api_robo_get_result_survey = "robo-advisor/process";
  public static api_robo_get_all = "res/all";

  public static api_upload_file =
    Constant.url_host_upload + Constant.api_path_upload_file;

  //Const url icon
  public static icon_host = Constant.icon_url_host;
  public static icon_url_loading = Constant.icon_host + "loading.svg";
  public static icon_url_close = Constant.icon_host + "close.png";
  public static icon_url_close_no_backround = Constant.icon_host + "close_no_backround.png";
  public static icon_url_warning = Constant.icon_host + "warning.svg";
  public static icon_url_warning_nbg = Constant.icon_host + "ic-warning.svg";
  public static icon_url_success_nbg = Constant.icon_host + "ic-success.svg";
  public static icon_url_logo = Constant.icon_host + "logo.png";
  public static icon_picture_default = Constant.icon_host + "user.png";
  public static icon_url_send_mail_done = Constant.icon_host + "sendMail.svg";
  public static logo_url_logo_white = Constant.logo_url_host + "fmarket-white.svg";

  //Const
  public static const_refresh_select = "refresh";
  public static const_time_out_set_interval = 5;
  public static const_time_out_interval = 30 * 60;
  public static const_time_out_data_service = 60 * 1000;
  public static const_page_max_size = 1000;
  public static const_type_pdf = "PDF";
  public static const_type_excel = "XLS";
  public static const_pagerSize_default = 25;
  public static const_pagerSize_top_manager = 15;
  public static const_timeout = 300000;
  public static const_default_format_number = ".0-2";
  public static const_default_format_date = "dd/MM/yyyy";
  public static const_default_format_date_option = "dd/mm/yyyy";
  public static const_decimal_limit = 2;
  public static const_max_size_upload = 5 * 1024 * 1024;
  public static const_max_size_picture = 5 * 1024 * 1024;
  public static default_maxlenght = 254;
  public static default_maxlenght_about = 255;
  public static max_length = 40;
  public static max_length_name_product = 20;
  public static max_length_day = 17;
  public static min_length_password = 8;
  public static min_length_email = 4;
  public static max_length_description = 160;
  public static max_length_name = 70;
  public static max_length_nickName = 16;
  public static KNOWLEDGE = "KNOWLEDGE";
  public static QA = "QA";
  public static FACEBOOK = "FACEBOOK";
  public static SKYPE = "SKYPE";
  public static TWITTER = "TWITTER";
  public static YOUTUBE = "YOUTUBE";
  public static MESSENGER = "MESSENGER";
  public static KAKAOTALK = "KAKAOTALK";
  public static WHATSAPP = "WHATSAPP";
  public static VIBER = "VIBER";
  public static WECHAT = "WECHAT";
  public static ZALO = "ZALO";
  public static ISSUER = "ISSUER";
  public static FUND_AGENT = "FUND_AGENT";
  public static BOND_AGENT = "BOND_AGENT";
  public static CD_AGENT = "CERTIFICATE_OF_DEPOSIT_AGENT";
  public static INVESTOR = "INVESTOR";
  public static ORDER_PENDING = "ORDER_PENDING";
  public static ORDER_INIT = "ORDER_INIT";
  public static ORDER_CANCEL = "ORDER_CANCEL";
  public static ORDER_REJECT = "ORDER_REJECT";
  public static ORDER_APPROVE = "ORDER_APPROVE";
  public static ORDER_PAYMENT = "PAYMENT";
  public static ORDER_ALLOCATE = "ALLOCATE";
  public static ORDER_RECONCILE = "RECONCILE";
  public static ORDER_ACCEPT = "ORDER_ACCEPT";
  public static ORDER_RECONCILED = "ORDER_RECONCILED";
  public static ORDER_PLEDGE = "ORDER_PLEDGE";
  public static ORDER_CANCEL_PLEDGE = "ORDER_CANCEL_PLEDGE";
  public static PROFILE_PENDING = "INVESTMENT_PROFILE_PENDING";
  public static PROFILE_CANCEL = "INVESTMENT_PROFILE_CANCEL";
  public static PROFILE_ACCEPT = "INVESTMENT_PROFILE_ACCEPT";
  public static PROFILE_APPROVED = "INVESTMENT_PROFILE_APPROVE";
  public static PROFILE_REJECT = "INVESTMENT_PROFILE_REJECT";
  public static PROFILE_INIT = "INVESTMENT_PROFILE_INIT";
  public static CHANGE_PROFILE_APPROVED =
    "INVESTMENT_PROFILE_CHANGE_REQUEST_APPROVE";
  public static CHANGE_PROFILE_REJECT =
    "INVESTMENT_PROFILE_CHANGE_REQUEST_REJECT";
  public static FUND_ISSUER = "FUND_ISSUER";
  public static BOND_ISSUER = "BOND_ISSUER";
  public static BOND_FUND_ISSUER = "BOND_FUND_ISSUER";
  public static CERTIFICATE_OF_DEPOSIT_ISSUER = "CERTIFICATE_OF_DEPOSIT_ISSUER";
  // Page
  public static RISK_SURVEY_MANULIFE_PAGE = "RISK_SURVEY_MANULIFE_PAGE";
  public static MARKET_PAGE = "MARKET_PAGE";
  public static ASSET_PAGE = "ASSET_PAGE";
  public static PROFILE_PAGE = "PROFILE_PAGE";
  public static REGISTER_TRADE_PAGE = "REGISTER_TRADE_PAGE";
  public static TRANSACTION_PAGE = "TRANSACTION_PAGE";
  public static REWARD_PAGE = "REWARD_PAGE";
  public static ROBO_PAGE = "ROBO_PAGE";
  // Regex
  public static REGEX_EMAIL = "/^w+@([0-9a-zA-Z]+.?)+$/";

  public static DAYS_OF_WEEK_WORKING_DAY = [1, 2, 3, 4, 5];

  public static extension_img =
    "jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG|bmp|BMP|bgp|BGP|PPM|ppm|PGM|pgm|pbm|PBM|ico|ICO|PGF|pgf|IMG|img";
  public static extension_file_doc =
    "pdf|PDF|xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|zip|ZIP|rar|RAR";

  //Product code
  public static CODE_ORDER_FUND = "FUND";
  public static CODE_NEW_FUND = "NEW_FUND";
  public static CODE_TRADING_FUND = "TRADING_FUND";
  public static CODE_ORDER_CD = "CD";
  public static CODE_GOLD = "GOLD";
  public static CODE_FIXED_INTEREST_ON_FIXED_CD = "FIXED_INTEREST_ON_FIXED_CD";
  public static CODE_FLEXIBLE_INTEREST_ON_FIXED_CD =
    "FLEXIBLE_INTEREST_ON_FIXED_CD";
  public static CODE_FLEXIBLE_CD = "FLEXIBLE_CD";
  public static CODE_ORDER_BOND = "BOND";
  public static CODE_BOND = "PRODUCT_BOND";
  public static CODE_FUND = "PRODUCT_FUND";
  public static CODE_CD = "PRODUCT_CD";
  public static CODE_MATURITY_BOND = "FLEXIBLE_YIELD_ON_FIXED_BOND";
  public static CODE_FIXED_BOND = "FIXED_BOND";
  public static CODE_FLEXIBLE_BOND = "FLEXIBLE_BOND";
  public static PRODUCT_CODE = "PRODUCT_FUND_NEW";
  public static PRODUCT_ACTIVE = "PRODUCT_ACTIVE";

  //Fee code
  public static BUY_FEE_CODE = "FEE_BUY";
  public static SELL_FEE_CODE = "FEE_SELL";
  //Order code
  public static CODE_ORDER_BUY = "BUY";
  public static CODE_ORDER_TRANSFER_BUY = "TRANSFER_BUY";
  public static CODE_ORDER_SELL = "SELL";
  public static CODE_ORDER_TRANSFER_SELL = "TRANSFER_SELL";
  public static CODE_ORDER_WITHDRAWAL = "WITHDRAW_GOLD";
  public static CODE_ORDER_PENDING = "PENDING";
  public static CODE_ORDER_ACCEPT = "ACCEPT";

  public static ANROID_APP =
    "https://play.google.com/store/apps/details?id=com.fincorp.fmarket";
  public static DEEPLINK_ANROID_APP = "market://details?id=com.fincorp.fmarket";
  public static IOS_APP =
    "https://apps.apple.com/vn/app/fmarket-smart-invest/id1585628917";
  public static DEEPLINK_IOS_APP = "https://itunes.apple.com/app/id1585628917";
  public static DEEPLINK_APP_TARGET = "fmarket://target";
  public static DEEPLINK_APP_ROBO_SUGGEST = "fmarket://robo_suggest";
  public static DEEPLINK_APP_REFUND = "fmarket://refund/orderId/";
  public static DEEPLINK_APP_PORTFOILIO = "fmarket://portfolio";
  public static DEEPLINK_APP_MARKET_FUND = "fmarket://market_ccq";
  public static DEEPLINK_APP_MARKET_GOLD = "fmarket://market_gold";
  public static DEEPLINK_APP_LOGIN = "fmarket://login";

  public static checkMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return window.screen.width < 768 ? check : false;
  }

  public static checkTablet() {
    let screen = window.screen.width;
    return screen >= 768 && screen <= 1024;
  }


  public static checkAndroid() {
    let userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public static checkIOS() {
    let userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
  }

  public static getInputAcceptDocFile() {
    return Constant.extension_file_doc
      .split("|")
      .map((item) => "." + item)
      .join(",");
  }

  public static getInputAcceptImgFile() {
    return Constant.extension_img
      .split("|")
      .map((item) => "." + item)
      .join(",");
  }

  public static arraysEqual(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every((el, ix) => el === b[ix]);
  }
}
