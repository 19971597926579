import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector, Injectable} from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { UserProfile } from 'src/app/common/models/users.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product/product.service';
import { DateUtil } from 'src/app/common/utils/date.util';
import { Product } from 'src/app/common/models/product.model';
import { OptionFee } from '../../product/components/detail-fee/detail-fee';
import { Constant } from 'src/app/common/constant';
import { SessionStorageService } from 'ngx-webstorage';
import { TabNav } from '../../common/components/tab-list/tab.model';
import { TranslateService } from '@ngx-translate/core';
const TABS = {
    OVERVIEW: 'overview',
    TOP_HOLDING: 'top_holding',
    HOLDING_INDEX: 'holding_index',
    INDUSTRY_DISTRIBUTION: 'industry_distribution',
    ASSET_DISTRIBUTION: 'asset_distribution',
    DOCUMENTS: 'documents'
}

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable({ providedIn: 'any' })
export abstract class DetailProductFunction implements OnInit, OnChanges, OnDestroy {
    @Input() isDetailFullPage;
    TABS = TABS;
    tab: string = TABS.OVERVIEW;
    product: Product = new Product();
    Constant = Constant;
    noteBuyFeeDiscount: any;
    buyFeesDiscount: any[] = [];
    noteSellFeeDiscount: any;
    sellFeesDiscount: any[] = [];
    optionBuyFee: OptionFee = new OptionFee({
        valueTitle: 'Giá trị mua',
        valueUnit: '',
        feeTitle: 'Phí mua',
        feeUnit: '%',
    });
    optionSellFee: OptionFee = new OptionFee({
        valueTitle: 'Thời gian nắm giữ',
        valueUnit: 'tháng',
        feeTitle: 'Phí bán',
        feeUnit: '%',
    });
    user: UserProfile = new UserProfile();
    isError: boolean = false;
    //chart 
    typeDataChart = '12';
    navChange: number = 0;
    public lineChartData: any[] = [{ data: [], label: 'NAV/Unit (VND)' }];
    public chartData: any = {
      labels: ['title'],
      datasets: [
        {
          label: 'label',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
          pointBackgroundColor: 'rgb(255, 99, 132)'
        }
      ]
    };
    approveDays: string = ' hàng tuần';
    subscriptions?: Subscription[] = [];
    isDevice: any = false;
    isBond: boolean = false;
    public transferFees = [];
    tabList: TabNav[] = [];
    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal,
        public translateService: TranslateService,
        public productService: ProductService) {
    }
    ngOnInit() {
        this.product = this.product || new Product();
        this.subscriptions = [
            this.appQueries.userProfile$
            .subscribe((userProfile) => {
                this.user = userProfile;
            }),
            this.appQueries.isDevice$.subscribe((isDevice) => {
                this.isDevice = isDevice;
            }),
            this.eventManager.subscribe('response-get-detail-product', (res) => {
                this.product = res.content.product || new Product();
                this.isBond = this.product.dataFundAssetType.code === 'BOND';
                this.initData();
            })
        ];
    }
    ngOnChanges() {
        this.product = this.product || new Product();
        if (this.product.code === 'TBLF') {
            this.approveDays = ': tuần thứ nhất và tuần thứ ba hàng tháng';
        }
        this.initData();
    }
    ngOnDestroy(): void {
        if (this.subscriptions.length > 0) {
            this.eventManager.destroys(this.subscriptions);
          }
    }
    setTimeZone() {
        this.product.extra = this.product.extra || {lastNAVDate: 0};
        const lastNAVDate = new Date(this.product.extra.lastNAVDate);
        this.product.extra.lastNAVDate = new Date(lastNAVDate.setHours(lastNAVDate.getHours() + 2)).getTime();
    }
    initData() {
        this.getDataChart(this.typeDataChart);
        this.updateProduct();

        const id = Date.now();
        this.tabList  = [
            new TabNav({ id, name:  this.translateService.instant('Tổng quan'), code: 'overview'}),
            new TabNav({ id, name:  this.translateService.instant('Danh mục đầu tư lớn'), code: 'top_holding'}),
            new TabNav({ id, name:  this.translateService.instant('Phân bổ theo tài sản'), code: 'asset_distribution'}),
            new TabNav({ id, name:  this.translateService.instant('Tài liệu'), code: 'documents'}),
        ];
        if (this.isBond) {
            this.tabList.splice(2, 0, new TabNav({ id, name:  this.translateService.instant('Phân bổ theo ngành'), code: 'industry_distribution'}));
        }
    }
    updateProduct() {
        if (this.product.code === 'TBLF') {
            this.approveDays = ': tuần thứ nhất và tuần thứ ba hàng tháng';
        } else {
            this.approveDays = '';
        }
        if (this.product.productTradingSession) {
            let closeBankNote = this.product.productTradingSession.closedBankNoteTimeString;
            closeBankNote = 'Trước ' + closeBankNote.substring(0, 5) + ' ngày ' + closeBankNote.substring(6, closeBankNote.length);
            this.product.productTradingSession.closedBankNoteString = closeBankNote;
            let closedOrderBookTimeString = this.product.productTradingSession.closedOrderBookTimeString;
            closedOrderBookTimeString = 'Trước ' + closedOrderBookTimeString.substring(0, 5) + ' ngày ' + closedOrderBookTimeString.substring(6, closedOrderBookTimeString.length);
            this.product.productTradingSession.closedOrderBookString = closedOrderBookTimeString;
        }
        this.product.feeBuys.forEach((item) => {
            if (item.beginRelationalOperator) {
                switch (item.beginRelationalOperator.name) {
                    case '>':
                        item.beginRelationalOperator.reverse = '<';
                        break;
                    case '>=':
                        item.beginRelationalOperator.reverse = '<=';
                        break;
                }
            }
            if (item.endRelationalOperator) {
                switch (item.endRelationalOperator.name) {
                    case '>':
                        item.endRelationalOperator.reverse = '<';
                        break;
                    case '<':
                        item.endRelationalOperator.reverse = '>';
                        break;
                    case '>=':
                        item.endRelationalOperator.reverse = '<=';
                        break;
                    case '<=':
                        item.endRelationalOperator.reverse = '>=';
                        break;
                }
            }
        });
        this.transferFees = []; const products = [];
        const transferList = this.product.productFeeList.filter(item => item.type === "TRANSFER");
        const filterByDestProduct = {};
        transferList.forEach((item) => {
            filterByDestProduct[item.destProductId] = filterByDestProduct[item.destProductId] || [];
            products[item.destProductId] = item.destProduct;
            filterByDestProduct[item.destProductId].push(item);
        });
        Object.keys(filterByDestProduct).forEach((productId) => {
            const fees = filterByDestProduct[productId] || [];
            const firstFee = fees[0] || {};
            const isOneFee = fees.every(fee => fee.fee === firstFee.fee);
            firstFee.isOneFee = isOneFee;
            this.transferFees.push({
                product: products[productId],
                fees: !isOneFee ? (filterByDestProduct[productId] || []).map((item) => {
                    switch (item.feeUnitType) {
                        case 'MONTH':
                            item.feeUnitTypeName = 'tháng';
                        break;
                        case 'MONEY':
                            item.feeUnitTypeName = '';
                        break;
                        case 'DAY':
                            item.feeUnitTypeName = 'ngày';
                        break;
                    }
                    return item;
                }) : [firstFee]
            })
        })
        this.product.productFeeDiscountList = this.product.productFeeDiscountList || [];
        this.buyFeesDiscount = this.product.productFeeDiscountList.filter(item => item.type === 'BUY');
        this.noteBuyFeeDiscount = this.buyFeesDiscount[0] ? this.buyFeesDiscount[0].note : '';
        this.sellFeesDiscount = this.product.productFeeDiscountList.filter(item => item.type === 'SELL');
        this.noteSellFeeDiscount = this.sellFeesDiscount[0] ? this.sellFeesDiscount[0].note : '';
        this.product.productTopHoldingList = this.product.productTopHoldingList || [];
        this.product.isNewProduct = !this.product.isProductIpo && (Date.now() -  this.product.approveAt) / (24 * 60 * 60 * 1000) < 30;
        if (this.product.fundReport) {
            this.product.fundReport.toString = `${this.product.fundReport.totalAssetValueStr} (báo cáo tháng ${DateUtil.parseDate(this.product.fundReport.reportTime, 'MM/yyyy')})`;
        }
    }
    getDataChart(type) {
        this.typeDataChart = type;
        let  fromDate, toDate;
        const lastNAVDate = new Date(this.product.extra.lastNAVDate);
        lastNAVDate.setDate(lastNAVDate.getDate() + 1);
        toDate = DateUtil.parseDate(lastNAVDate, 'yyyyMMdd');
        let date = new Date();
        const currentTime = this.product.extra.lastNAVDate;
        const navChange = this.product.productNavChange || {};
        switch (this.typeDataChart) {
            case '3':
                this.navChange = navChange.navTo3Months;
                // date.setMonth(date.getMonth() -3);
                date = new Date(currentTime - 91 * 86400000);
            break;
            case '6':
                this.navChange = navChange.navTo6Months;
                // date.setMonth(date.getMonth() -6);
                date = new Date(currentTime - 182 * 86400000);
            break;
            case '12':
                this.navChange = navChange.navTo12Months;
                // date.setMonth(date.getMonth() - 12);
                date = new Date(currentTime - 365  * 86400000);
            break;
            case '36':
                this.navChange = navChange.navTo36Months || navChange.navToBeginning;
                // date.setMonth(date.getMonth() - 36);
                date = new Date(currentTime - 3 * 365 * 86400000);
            break;
            case 'ALL':
                this.navChange = navChange.navToBeginning;
            break;
        }
        date.setDate(date.getDate() - 2);
        fromDate = DateUtil.parseDate(date, 'yyyyMMdd');;
        const body = {
            isAllData: type === 'ALL' ? 1 : 0,
            productId: this.product.id,
            fromDate: type !== 'ALL' ? fromDate : null,
            toDate: toDate || null,
        }
        this.productService.getDataChart(body)
        .subscribe((res: any) => {
            const labels = res.data.map((item: any) => {
                return item.navDate;
            });
            this.chartData = {
                dateLabels: labels,
                labels: labels,
                datasets: [
                  {
                    label: 'NAV',
                    data: res.data.map((item: any) => {
                      return item.nav;
                    }),
                    isColorBlue: true,
                    isFill: false
                  }
                ]
            };
        })
    }
    buy() {
        if (this.isDetailFullPage) {
            const message = `patners://buy-fund/{"code": ${this.product.code}}`;
            window.location.href = message;
            return;
        }
        if (this.user && this.user.id) {
            const currentPage = this.sessionStorage.retrieve('currentPage');
            if (currentPage !== Constant.REWARD_PAGE) {
                this.router.navigate([Constant.route_market]);
            }
            setTimeout(() => {
                this.eventManager.broadcast({ 
                    name: 'check-e-sign', 
                    content: {
                        product: this.product
                    }  
                });
            }, 500)
        } else {
            this.router.navigate([Constant.url_login]);
        }
        this.dismiss();
    }
    dismiss() {
        if (this.isDetailFullPage) {
            const message = `patners://close-popup-detail-fund`;
            window.location.href = message;
            return;
        }
        this.activeModal.dismiss();
    }
    changeTab(tab) {
        this.tab = tab || 'overview';
    }
}
