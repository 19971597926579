import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product/product.service';
import { DetailProductFunction } from '../../../main/components/detail-product.abstract.function';
import { Constant } from 'src/app/common/constant';
import { Subscription } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'detail-gold',
    templateUrl: './detail-gold.html',
    styleUrls: ['./detail-gold.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DetailGold extends DetailProductFunction implements OnInit, OnChanges, OnDestroy {
    @Input() product;
    @Input() buyInfo;
    @Input() bankInfo: any = {};
    Constant = Constant;
    isDevice: any = false;

    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal,
        public translateService: TranslateService,
        public productService: ProductService,) {
        super(eventManager, appQueries, router, sessionStorage, activeModal, translateService, productService)
    }
    updateProduct() {}
    buy() {
        this.dismiss();
        this.router.navigate([Constant.route_market_gold]);
        setTimeout(() => {
            this.eventManager.broadcast({
                name: 'choose-gold-buy',
                content: {
                  product: this.product
                }
            });
        }, 500)
       
    }
}
